import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

const servicesData = [
  {
    id: 1,
    icon: "images/react-2.svg",
    title: "Front-End Development",
    content: "React.JS, Next.JS, HTML5, CSS, Node.JS",
    color: "#4a8ddc",
    contentColor: "light",
  },
  {
    id: 2,
    icon: "images/logo-javascript.svg",
    title: "Back-End Development",
    content: "Node.JS, Express.JS",
    color: "#dd915f",
    contentColor: "dark",
  },
  {
    id: 3,
    icon: "images/amazon-web-services-2.svg",
    title: "Cloud Services",
    content:
      "S3, Elastic Beanstalk, Lambda, EC2, IAM, AppFlow, Route 53, CodePipeline, Azure, Docker, Kubernetes",
    color: "#dc5b57",
    contentColor: "light",
  },
  {
    id: 4,
    icon: "images/amazon-database.svg",
    title: "CRM/SQL/NoSQL",
    content: "MySQL, Snowflake, Salesforce, MongoDB,",
    color: "#33ae81",
    contentColor: "light",
  },
];

function Services() {
  return (
    <section id="services">
      <div className="container">
        <Pagetitle title="Skills and Technologies" />
        <div className="row fix-spacing">
          {servicesData.map((service) => (
            <div className="col-md-3" key={service.id}>
              <Service service={service} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
