import React, { useState, useEffect } from "react";
import Header from "../components/layouts/Header";
import Blog from "../components/elements/Blog";
import Pagination from "../components/elements/Pagination";

const allBlogs = [
  {
    id: 11,
    title: "Burgiss Technology Stack Demo",
    image: "https://i.imgur.com/sXfJHWP.png",
    filesource: "../../blogs/11.md",
    date: "Angular, ASP.NET Core 6, SQL Server, Azure, Docker, Kubernetes",
    author: "",
    category: "Personal",
  },
  {
    id: 1,
    title: "Rutgers Hackathon - First Place Winner",
    image: "https://i.imgur.com/BOzM5ol.jpg",
    filesource: "../../blogs/1.md",
    date: "React Native, Express.JS, Node.JS, MongoDB",
    author: "",
    category: "Competitive",
  },
  {
    id: 2,
    title: "Tableau and Fedex API Integration",
    image: "https://i.imgur.com/w3IJq1a.png",
    filesource: "../../blogs/2.md",
    date: "Python3, Tableau, Fedex API, AWS EC2",
    author: "",
    category: "Experience",
  },
  {
    id: 3,
    title: "SFDC Heat Map",
    image: "https://i.imgur.com/rQwd4Av.png",
    filesource: "../../blogs/3.md",
    date: "Node.JS, Express.JS, AWS Elastic Beanstalk, AWS CodePipeline, SFDC, SQL",
    author: "",
    category: "Experience",
  },
  {
    id: 4,
    title: "Email Scripting - Attachment Transfer To S3 Automation",
    image: "https://i.imgur.com/PGLxRBc.png",
    filesource: "../../blogs/4.md",
    date: "Python3, boto3, AWS S3, imaplib, email",
    author: "",
    category: "Experience",
  },
  {
    id: 5,
    title: "SFDC HTTPS Full Stack Web Application",
    image: "https://i.imgur.com/sCXCbIH.png",
    filesource: "../../blogs/5.md",
    date: "SFDC, AWS EC2, SSL, React.JS, Express.JS, Node.JS, SQL, JWT",
    author: "",
    category: "Experience",
  },
  {
    id: 6,
    title: "SFDC to Snowflake Daily Data Transfer",
    image: "https://i.imgur.com/NWXN6a3.png",
    filesource: "../../blogs/6.md",
    date: "Python3, SFDC, AWS EC2, Snowflake, SQL",
    author: "",
    category: "Experience",
  },
  {
    id: 7,
    title: "Hashing MongoDB Data for Security",
    image: "https://i.imgur.com/rn8Lq0B.png",
    filesource: "../../blogs/7.md",
    date: "MongoDB, ByCrypt, Node.JS",
    author: "",
    category: "Personal Business",
  },
  {
    id: 8,
    title: "Username and Password + Twilio Phone Authentication System",
    image: "https://i.imgur.com/Jaf0CMO.png",
    filesource: "../../blogs/8.md",
    date: "Twilio, MongoDB, Node.JS, Next.JS",
    author: "",
    category: "Personal Business",
  },
  {
    id: 9,
    title: "Web Application with Session Saving and Cookies - JWT",
    image: "https://i.imgur.com/hvGsmPV.png",
    filesource: "../../blogs/9.md",
    date: "Node.JS, Next.JS, JWT, Cookies",
    author: "",
    category: "Personal Business",
  },
  {
    id: 10,
    title: "Portfolio Website Built in React.JS",
    image: "https://i.imgur.com/SpvjJE0.png",
    filesource: "../../blogs/10.md",
    date: "React.JS, SCSS, CSS, HTML5, AWS Route 53, AWS S3 ",
    author: "",
    category: "Personal",
  },
];

function Bloglist() {
  document.body.classList.add("dark");
  //Uncomment the above line if you use dark version

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() => {
    setPosts(allBlogs);
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (e, pageNumber) => {
    window.scrollTo(0, 0);
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  return (
    <>
      <Header
        logoSource="/images/logo.svg"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />

      <main className={toggleMenu ? "content open" : "content"}>
        <div className="spacer" data-height="96"></div>
        <div className="blog-page-section">
          <div className="container">
            <a className="btn btn-default go-down" href="/">
              Close Project Page
            </a>
            <div className="row blog-wrapper fix-spacing">
              {currentPosts.map((blogItem) => (
                <div className="col-md-6" key={blogItem.id}>
                  <Blog blogData={blogItem} />
                </div>
              ))}
            </div>
            <div className="spacer" data-height="50"></div>
            {!(posts.length > postsPerPage) ? null : (
              <Pagination
                itemsPerPage={postsPerPage}
                totalItems={posts.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default Bloglist;
