import React from "react";
import { Link } from "react-router-dom";
import Blog from "../elements/Blog";
import Pagetitle from "../elements/Pagetitle";

const allBlogs = [
  {
    id: 11,
    title: "Burgiss Technology Stack Demo",
    image: "https://i.imgur.com/sXfJHWP.png",
    filesource: "../../blogs/11.md",
    date: "Angular, ASP.NET Core 6, SQL Server, Azure, Docker, Kubernetes",
    author: "",
    category: "Personal",
  },
  {
    id: 1,
    title: "Rutgers Hackathon - First Place Winner",
    image: "https://i.imgur.com/BOzM5ol.jpg",
    filesource: "../../blogs/1.md",
    date: "React Native, Express.JS, Node.JS, MongoDB",
    author: "",
    category: "Competitive",
  },
  {
    id: 5,
    title: "SFDC HTTPS Full Stack Web Application",
    image: "https://i.imgur.com/sCXCbIH.png",
    filesource: "../../blogs/5.md",
    date: "SFDC, AWS EC2, SSL, React.JS, Express.JS, Node.JS, SQL, JWT",
    author: "",
    category: "Experience",
  },
];

document.body.classList.add("dark");

function Blogs() {
  return (
    <section id="blog">
      <div className="container">
        <Pagetitle title="Noteworthy Projects" />
        <div className="rounded bg-white shadow-dark padding-30 marginbot-30">
          I do not have authorization to post about many of the projects I have
          worked on due to client preference. However, there are still many
          projects I am authorized to share that I am proud of. Many of these
          projects include self made tutorials of how I implemented certain
          technologies to demonstrate my knowledge in them, as well as code
          snippets of projects that are currently in production. There are also
          projects that I activly use in any of my three co-founded buisnesses.
        </div>
        <div className="row blog-wrapper">
          {allBlogs.map((blogItem) => (
            <div className="col-md-4" key={blogItem.id}>
              <Blog blogData={blogItem} />
            </div>
          ))}
        </div>
        <div className="text-center">
          <div className="spacer" data-height="30"></div>
          <Link to="/blogs" className="btn btn-default">
            Show all projects
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Blogs;
