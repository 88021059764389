import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";
import pdf from "../elements/Krishna_Satti_Resume.pdf";

const aboutContent = {
  name: "Krishna Satti",
  avatarImage: "/images/avatar-2.svg",
  content:
    "I am a skilled software developer with a strong educational background in Computer Science. My technical proficiency includes Java, Python, JavaScript, TypeScript, HTML/CSS, SQL, NoSQL, Git, Jira, and experience with popular cloud services such as Amazon Web Services and Azure. I have hands-on experience in building web applications using frameworks such as Express.JS, React.JS, React Native, Spring Boot, and AngularJS. I have also worked with server technologies like NGINX and container orchestration tools like Kubernetes. My professional experience includes a software developer role at Prime Solutions, where I designed and developed REST APIs, Python applications, and full-stack web applications that improved performance and efficiency. I also have a track record of teamwork and leadership, demonstrated through the great amount of projects I have completed while working closley with clients and team mebers.",
};

const progressData = [
  {
    id: 1,
    title: "Back-End Development",
    percantage: 100,
    progressColor: "#4a8ddc",
  },
  {
    id: 1,
    title: "Front-End Development",
    percantage: 85,
    progressColor: "#dd915f",
  },
  {
    id: 3,
    title: "Amazon Web Services",
    percantage: 100,
    progressColor: "#dc5b57",
  },
  {
    id: 4,
    title: "CRM / SQL / NoSQL",
    percantage: 80,
    progressColor: "#33ae81",
  },
];

const counterData = [
  {
    id: 1,
    title: "Projects completed",
    count: 100,
    icon: "icon-fire",
  },
  {
    id: 2,
    title: "Cups of coffee",
    count: 1095,
    icon: "icon-cup",
  },
  {
    id: 3,
    title: "Satisfied clients",
    count: 20,
    icon: "icon-people",
  },
  {
    id: 4,
    title: "Professionaly Applied Technologies",
    count: 35,
    icon: "icon-docs",
  },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-2">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div>
                  <p>{aboutContent.content}</p>
                  <div className="mt-1">
                    <a href={pdf} download className="btn btn-default">
                      Download CV
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="spacer" data-height="70"></div>
          <div className="row fix-spacing">
            {counterData.map((counter) => (
              <div key={counter.id} className="col-md-3 col-sm-6">
                <TrackVisibility once>
                  <Counter counterItem={counter} />
                </TrackVisibility>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
