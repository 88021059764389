import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Rutgers, The State University of New Jersey",
    years: "2018 - 2022",
    content: "New Brunswick",
  },
  {
    id: 2,
    title: "Bachelor’s Degree of Science",
    years: "",
    content: "Computer Science",
  },
  {
    id: 3,
    title: "Relevant Coursework",
    years: "",
    content:
      "Imaging and Multimedia, Introduction to Artificial Intelligence, Computer Security, Internet Technology, Systems Programming, Principals of Programming Languages, Design and Analysis Computer Algorithms, Principals of Information and Data Managment, Computer Architecture, Data Structures, Discrete Mathematics",
  },
];

const experienceData = [
  {
    id: 2,
    title: "Software Developer",
    years: "October 2020 - Current",
    content: "Prime Solutions - Plainview, NY",
  },
  {
    id: 1,
    title: "Software Developer Intern",
    years: "June 2020 - September 2020",
    content: "Prime Solutions - Plainview, NY",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
